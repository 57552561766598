import moment from 'moment';
import { useEffect, useState } from "react";
import {
  Header, Segment, Dropdown, Table, Input, Checkbox, Button, Icon, Label,
  SegmentGroup
} from 'semantic-ui-react'
import Swal from 'sweetalert2';

// API
import AdminAPI from "../api/admin";

// Components
import Loading from "../components/Main/Loading";
import Util from "../util";
import Constants from "../constants/constants";


const Admin = () => {
  const [isLoading, setLoading] = useState(true);
  const [isLoadingTeams, setLoadingTeams] = useState(false);
  const [isLoadingSave, setLoadingSave] = useState(false);
  const [allCompetitions, setAllCompetitions] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [competitionsAvailable, setCompetitionsAvailable] = useState([]);
  const [teamsAvailable, setTeamsAvailable] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState(undefined);
  const [homeTeam, setHomeTeam] = useState(undefined);
  const [awayTeam, setAwayTeam] = useState(undefined);
  const [type, setType] = useState(undefined);
  const [year, setYear] = useState(2024);
  const [month, setMonth] = useState(1);
  const [day, setDay] = useState(1);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [ko, setKO] = useState(false);
  const [includesET, setIncludesET] = useState(false);
  const [bonusPoints, setBonusPoints] = useState(undefined);
  const [eventName, setEventName] = useState(undefined);

  // Define the Match Status available
  const matchTypesOptions = [
    {
      key: Constants.MATCH_TYPE__1X2,
      text: Constants.MATCH_TYPE__1X2.toUpperCase(),
      value: Constants.MATCH_TYPE__1X2,
    },
    {
      key: Constants.MATCH_TYPE__PODIUM,
      text: Constants.MATCH_TYPE__PODIUM.toUpperCase(),
      value: Constants.MATCH_TYPE__PODIUM,
    },
  ]

  useEffect(() => {
    async function getCreationElements() {
      try {
        const creationElements = await AdminAPI.getMatchCreationElements();

        const { competitions, teams } = creationElements;

        // Save the objects as they come
        setAllTeams(teams);
        setAllCompetitions(competitions);

        // Format competitions into the dropdown options
        const competitionsOptions = competitions.map((competition) => {
          return {
            key: competition.name,
            text: competition.name,
            value: competition._id,
          }
        });
        setCompetitionsAvailable(competitionsOptions);
      } catch (error) {
        Util.handleError(error);
      }
    }

    try {
      // Reset loader
      setLoading(true);

      // Get matches
      getCreationElements();

      // Get current time in GMT
      const time = moment();
      setYear(time.year());
      setMonth(time.month() + 1);
      setDay(time.date());
      setHour(time.hour());
      setMinute((time.minute() / 5).toFixed() * 5);

      setLoading(false);
    } catch(error) {
      Util.handleError(error, 'Error getting creation elements. Please retry');
    }
  }, []);

  /**
   * Handler called when selecting a new competition
   * @returns {void}
   */
  const updateSelectedCompetition = async (newCompetitionId) => {
    try {
      setLoadingTeams(true);
      // Reset existing teams
      setHomeTeam(undefined);
      setAwayTeam(undefined);

      // Find new teams
      const competition = allCompetitions.find((competition) => competition._id === newCompetitionId);
      const validTeams = allTeams.filter((team) => (!team.sport || team.sport === competition.sport.name));

      // Format competitions into the dropdown options
      const teamsOptions = validTeams.map((team) => {
        return {
          key: team.name,
          text: team.name,
          value: team._id,
        }
      });

      setSelectedCompetition(newCompetitionId);
      setTeamsAvailable(teamsOptions);
      setLoadingTeams(false);
    } catch (error) {
      Util.handleError(error);
      setLoadingTeams(false);
    }
  }

  /**
   * Handler called when updating the result of a match
   * @returns {void}
   */
  const handleSave = async () => {
    try {
      let update = '';
      const competitionObject = allCompetitions.find((competition) => competition._id === selectedCompetition);
      const time = moment({
        year: year,
        month: month - 1,
        date: day,
        hour,
        minute
      });

      const utcTime = time.utc().format();
      
      // Build the update message we are going to show
      if (type === Constants.MATCH_TYPE__1X2) {
        const homeTeamObject = allTeams.find((team) => team._id === homeTeam);
        const awayTeamObject = allTeams.find((team) => team._id === awayTeam);
        update = `Creating match for ${competitionObject.name}: ${homeTeamObject.name}-${awayTeamObject.name} on ${time.local()} [UTC: ${utcTime}]`;
      } else {
        update = `Creating event for ${competitionObject.name} ${eventName} at your ${time} [UTC: ${utcTime}`;
      }

      Swal.fire({
        title: 'Are you sure?',
        text: update,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        confirmButtonColor: '#439775',
        cancelButtonColor: 'lightgrey',
      }).then(async (result) => {
        // If the user confirms, we save in the Database
        if (result.isConfirmed) {
          let result;
          setLoadingSave(true);
          try {
            // Check type of match
            if (type === Constants.MATCH_TYPE__1X2) {
              // 1x2 match handler
              result = await AdminAPI.create1X2Match(
                  selectedCompetition,
                  homeTeam,
                  awayTeam,
                  utcTime,
                  ko,
                  includesET,
                  bonusPoints
              );

              if (result.success) {
                Swal.fire('Saved!', '', 'success');
                setLoading(false);
              } else {
                Util.handleError(result.data);
              }
            } else if (type === Constants.MATCH_TYPE__PODIUM) {
              // Podium match handler
              result = await AdminAPI.createPodiumMatch(
                  selectedCompetition,
                  eventName,
                  utcTime,
                  bonusPoints
              );

              if (result.success) {
                Swal.fire('Saved!', '', 'success');
                setLoading(false);
              } else {
                Util.handleError(result.data);
              }
            } else {
              // No type selected
              Util.handleError(null, 'No type selected');
              return;
            }
          } catch (error) {
            Util.handleError(error);
          }

          setLoadingSave(false);
        }
      });
    } catch(error) {        
      // Fire error
      Util.handleError(error, 'We couldn\'t save your bet, please retry');
  }
    
  };

  return isLoading ?
    (
      <Loading/>
    ) :
    (
      <div className="main ui container">
        <Segment basic>
          <Header>Admin Zone</Header>
          <p>
            From this page you can create new matches and add them to Ballkie
          </p>
          <p>
            A great power comes with a great responsability. Be careful!
          </p>
        </Segment>
          <Dropdown
            placeholder='Select a competition'
            options={competitionsAvailable}
            value={selectedCompetition}
            fluid
            selection
            search
            clearable
            onChange={(event, target) => updateSelectedCompetition(target.value)}
          />
          { isLoadingTeams ? 
            (<Loading/>):
            (
              <>
                <Table celled>
                  <Table.Body>
                    <Table.Row textAlign='center'>
                      <Header>Match Info</Header>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign='center'>
                        <SegmentGroup basic piled compact style={{margin: 0}}>
                          <Segment textAlign='center' inverted>YEAR</Segment>
                          <Segment textAlign='center'><Icon size='large' name='arrow up' onClick={() => setYear(year + 1)} /></Segment>
                          <Segment textAlign='center'><Label basic>{year}</Label></Segment>
                          <Segment textAlign='center'><Icon size='large' name='arrow down' onClick={() => setYear(year - 1)} /></Segment>
                        </SegmentGroup>
                        <SegmentGroup basic piled compact style={{margin: 0}}>
                          <Segment textAlign='center' inverted>MONTH</Segment>
                          <Segment textAlign='center'><Icon size='large' name='arrow up' onClick={() => setMonth(month === 12 ? 1 : month + 1)} /></Segment>
                          <Segment textAlign='center'><Label basic>{month}</Label></Segment>
                          <Segment textAlign='center'><Icon size='large' name='arrow down' onClick={() => setMonth(month === 1 ? 12 : month - 1)} /></Segment>
                        </SegmentGroup>
                        <SegmentGroup basic piled compact style={{margin: 0}}>
                          <Segment textAlign='center' inverted>DAY</Segment>
                          <Segment textAlign='center'><Icon size='large' name='arrow up' onClick={() => setDay(day === 31 ? 1 : day + 1)} /></Segment>
                          <Segment textAlign='center'><Label basic>{day}</Label></Segment>
                          <Segment textAlign='center'><Icon size='large' name='arrow down' onClick={() => setDay(day === 1 ? 31 : day - 1)} /></Segment>
                        </SegmentGroup>
                        <SegmentGroup basic piled compact style={{margin: 0}}>
                          <Segment textAlign='center' inverted>HOUR</Segment>
                          <Segment textAlign='center'><Icon size='large' name='arrow up' onClick={() => setHour(hour === 23 ? 0 : hour + 1)} /></Segment>
                          <Segment textAlign='center'><Label basic>{hour}</Label></Segment>
                          <Segment textAlign='center'><Icon size='large' name='arrow down' onClick={() => setHour(hour === 0 ? 23 : hour - 1)} /></Segment>
                        </SegmentGroup>
                        <SegmentGroup basic piled compact style={{margin: 0}}>
                          <Segment textAlign='center' inverted>MINUTE</Segment>
                          <Segment textAlign='center'><Icon size='large' name='arrow up' onClick={() => setMinute(minute === 55 ? 0 : minute + 5)} /></Segment>
                          <Segment textAlign='center'><Label basic>{minute}</Label></Segment>
                          <Segment textAlign='center'><Icon size='large' name='arrow down' onClick={() => setMinute(minute === 0 ? 55 : minute - 5)} /></Segment>
                        </SegmentGroup>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        Type
                      </Table.Cell>
                      <Table.Cell>
                      <Dropdown
                        placeholder='Select a type'
                        options={matchTypesOptions}
                        value={type}
                        fluid
                        selection
                        search
                        clearable
                        onChange={(event, target) => setType(target.value)}
                      />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        Bonus Points
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          type='number'
                          fluid
                          value={bonusPoints}
                          onChange={event => setBonusPoints(event.target.valueAsNumber)}
                        />
                      </Table.Cell>
                    </Table.Row>
                    { type === Constants.MATCH_TYPE__1X2 ?
                      (
                        <Table.Row>
                          <Table.Cell>
                            Extra time management
                          </Table.Cell>
                          <Table.Cell>
                            <Checkbox
                              toggle
                              value={ko}
                              label='KO match'
                              onChange={event => setKO(!ko)}
                            /><br /><br />
                            <Checkbox
                              toggle
                              value={includesET}
                              label='Includes ET'
                              onChange={event => setIncludesET(!includesET)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ):
                      (<></>)
                    }
                  </Table.Body>
                </Table>
                { type === Constants.MATCH_TYPE__1X2 ?
                  (
                    <Table>
                      <Table.Body>
                      <Table.Row textAlign='center'>
                          <Header>Teams</Header>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell width={4}>
                            <Header size='small'>Home Team</Header>
                            <Dropdown
                              placeholder='Select a team'
                              options={teamsAvailable}
                              value={homeTeam}
                              fluid
                              selection
                              search
                              clearable
                              onChange={(event, target) => setHomeTeam(target.value)}
                            />
                          </Table.Cell>
                          <Table.Cell width={4}>
                            <Header size='small'>Away Team</Header>
                            <Dropdown
                              placeholder='Select a team'
                              options={teamsAvailable}
                              value={awayTeam}
                              fluid
                              selection
                              search
                              clearable
                              onChange={(event, target) => setAwayTeam(target.value)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  ) :
                  (
                    <Table>
                      <Table.Body>
                      <Table.Row textAlign='center'>
                          <Header>Event Info</Header>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell width={4}>
                            Name
                          </Table.Cell>
                          <Table.Cell width={4}>
                            <Input
                              fluid
                              value={eventName}
                              onChange={event => setEventName(event.target.value)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  )
                }
                <Button
                  fluid
                  primary
                  icon='save'
                  onClick={() => handleSave()}
                  loading={isLoadingSave}
                />
              </>
            )
          }
      </div>
    )
    

};

export default Admin;
